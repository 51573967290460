@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* HTML: typing loader styles */
.loader {
  width: 20px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #0000007b 90%, #0000000b);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

//scrollbar styles

.infinite-scroll-component {
  &::-webkit-scrollbar {
    width: 5px; /* Chrome, Safari, and Opera */
  }

  &::-webkit-scrollbar-track {
    background: #fff; /* Scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #25add0; /* Scrollbar thumb */
    border-radius: 10px; /* Optional: for rounded corners */
  }
}

//circular loader styles

.circular-loader {
  position: absolute; /* Change to absolute to not affect layout */
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #25add0; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-container {
  max-width: 1280px;
  height: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.header {
  max-width: calc(1280px - 40px);
  width: calc(100% - 40px);
  position: fixed;
  top: 0;
  background-color: #ffffff;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  display: flex;
  box-shadow: 1px 2px 12px rgba(45, 45, 45, 0.1);

  .header-left {
    align-items: center;
    display: flex;
    width: 150px;

    .logo {
      width: 100%;
      height: auto;
    }
  }

  .header-right {
    align-items: center;
    display: flex;

    .visit-link {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      color: #25add0;
      letter-spacing: 0.5px;
      text-decoration: none;
    }
  }
}

.title {
  color: #000;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 1.5;
}

.description {
  color: #404659;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.5;
}
